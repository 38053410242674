<template>
  <div>
    <form-section :title="$t('courierSet.parcelContent.title')">
      <v-row>
        <v-col>
          <text-area-field
            v-model="getDefaultValues.allegroDefaultValues.description"
            :title="$t('courierSet.parcelContent.defaultParcelContent')"
            rules="required|max:165|min:3"
          />
        </v-col>
        <v-col
          v-for="item in getItems('CONTENT')"
          :key="generateUid() + '_' + item.sourceField"
          cols="12"
          class="py-3"
        >
          <v-select
            v-model="item.sourceField"
            :items="getFieldsToMap('CONTENT', item.sourceField)"
            :menu-props="{ bottom: true, offsetY: true }"
            item-value="value"
            item-text="text"
            append-icon="mdi-chevron-down"
            :label="$t('courierSet.parcelContent.title')"
            dense
            hide-details
            outlined
            cache-items
            persistent-placeholder
            clearable
            @change="onChange(item)"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.parcelContent.referenceNumber')">
      <v-row>
        <v-col
          v-for="item in getItems('REFERENCE_NUMBER')"
          :key="generateUid() + '_' + item.sourceField"
          class="pb-3"
        >
          <v-select
            v-model="item.sourceField"
            :items="getFieldsToMap('REFERENCE_NUMBER', item.sourceField)"
            :label="$t('courierSet.parcelContent.referenceNumber')"
            :menu-props="{ bottom: true, offsetY: true }"
            item-value="value"
            item-text="text"
            append-icon="mdi-chevron-down"
            dense
            hide-details
            outlined
            cache-items
            persistent-placeholder
            clearable
            @change="onChange(item)"
          />
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script>
import { FIELDS } from '@/constants/FieldsToMap.js';
import additionalFieldsMixin from '@/mixins/additionalFieldsMixin';
import { mapGetters, mapActions } from 'vuex';

const ADDITIONAL_FIELDS_CONFIGURATION = [
  {
    name: 'packageContent',
    code: 'CONTENT',
    displayAddFieldButton: true,
  },
  {
    name: 'packageReferenceNumber',
    code: 'REFERENCE_NUMBER',
    displayAddFieldButton: true,
  },
];

const COURIER_FIELDS = [
  {
    clientEntityState: '4',
    id: '',
    tenantId: '',
    sourceField: null,
    destinationField: 'CONTENT',
  },
  {
    clientEntityState: '4',
    id: '',
    tenantId: '',
    sourceField: null,
    destinationField: 'REFERENCE_NUMBER',
  },
];

export default {
  name: 'ContentAndComments',
  mixins: [additionalFieldsMixin],
  data: () => ({
    FIELDS,
    ADDITIONAL_FIELDS_CONFIGURATION,
    COURIER_FIELDS,
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet']),
    getDefaultValues() {
      return this.getConfigurationSet();
    },
  },

  watch: {
    getFieldsMappings: {
      handler(newValue) {
        if (!newValue) {
          this.setFieldsMappings({
            fieldsMappings: {
              clientEntityState: '4',
              id: '',
              tenantId: '',
              mappings: COURIER_FIELDS,
            },
          });
        }
      },
      deep: true,
    },
  },

  methods: {
    ...mapActions('spedition', ['setFieldsMappings']),
  },
};
</script>

<style scoped lang="scss"></style>
