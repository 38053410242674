<template>
  <div>
    <form-section>
      <div class="information-div">
        <v-icon
          color="primary lighten-1"
          class="px-5"
        >
          mdi-information-outline
        </v-icon>
        <span
          class="py-2"
          :style="{ fontSize: '0.75rem' }"
        >
          {{ $t('courierSet.authorization.appRegistration', { courier: 'Allegro' }) }}.
          <br />
          <span class="text--text-lighten1">
            *{{ $t('courierSet.authorization.singleRegistrationInfo') }}.
          </span>
        </span>
      </div>
      <div style="text-align: end; padding: 5px 0px">
        <span
          class="link"
          :style="{ fontSize: '0.75rem' }"
        >
          <a
            href="https://apps.developer.allegro.pl/"
            target="_blank"
          >
            https://apps.developer.allegro.pl/
          </a>
        </span>
      </div>
      <v-divider class="mb-1" />
      <div class="redirection-info-div">
        <span
          class="text--text-lighten1"
          :style="{ fontSize: '0.75rem' }"
        >
          {{ $t('courierSet.redirectURL') }}:
          <br />
          <span class="text--text caption">{{ redirectUri }}</span>
        </span>
        <v-btn
          elevation="0"
          color="white"
          class="pa-0"
          @click="copyToClipboard(redirectUri)"
        >
          <v-icon
            class="pa-0"
            color="primary lighten-1"
          >
            mdi-content-copy
          </v-icon>
        </v-btn>
      </div>
    </form-section>

    <form-section
      :title="$t('courierSet.restAuthData')"
      :tooltip="{ icon: 'mdi-help-circle-outline' }"
    >
      <template v-slot:message>
        <span
          class="font-weight-bold"
          style="font-size: 16px"
        >
          {{ $t('courierSet.restAuthData') }}
        </span>
        <br />
        <span class="d-block my-2 tooltip">
          <span class="font-weight-bold text--primary-lighten2 tooltip">
            {{ $tc('courierSet.allegroAuthTooltip', 0) }}
          </span>
          <span class="tooltip">{{ $tc('courierSet.allegroAuthTooltip', 1) }}</span>
          <span class="font-weight-bold text--primary-lighten2 tooltip">
            {{ $tc('courierSet.allegroAuthTooltip', 2) }}
          </span>
          <span class="tooltip">{{ $tc('courierSet.allegroAuthTooltip2', 0) }}</span>
          <br />
          <v-divider class="bg--priority_gray my-2" />
          <span class="font-weight-bold text--primary-lighten2 tooltip">
            https://apps.developer.allegro.pl
          </span>
        </span>
      </template>
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationData.clientId"
            :title="$t('courierSet.clientId')"
            rules="required"
            :disabled="isAuthorized"
          />
        </v-col>
        <v-col>
          <text-field
            v-model="getConfigurationData.apiClientSecret"
            :title="$t('courierSet.clientSecret')"
            rules="required"
            type="password"
            :disabled="isAuthorized"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <select-field
            v-model="environment"
            :title="$t('courierSet.environment')"
            :filed-items="allegroEnvironments"
            :rules="'required'"
            :disabled="isAuthorized"
          />
        </v-col>
      </v-row>
      <v-row v-if="!isAuthorized">
        <v-col cols="6">
          <v-btn
            color="primary lighten-1"
            elevation="0"
            width="100%"
            @click="onAllegroAuthorization"
          >
            ZALOGUJ DO ALLEGRO*
          </v-btn>
          <span class="d-block text--primary-lighten1">*Wymagane do rozpoczęcia konfiguracji.</span>
        </v-col>
      </v-row>
      <v-row v-else>
        <v-col
          col="12"
          lg="6"
        >
          <div class="authorization-info">
            <v-icon
              color="success"
              class="pr-2"
            >
              mdi-check-circle-outline
            </v-icon>
            <span>
              <span class="text--success font-weight-bold">
                {{ $tc('courierSet.authorization.succeed', 1) }}
              </span>
              {{ $tc('courierSet.authorization.succeed', 2) }}.
            </span>
          </div>
        </v-col>
        <v-col
          col="12"
          lg="6"
        >
          <v-btn
            color="primary lighten-1"
            elevation="0"
            :disabled="isNew"
            @click="onRefreshAllegroToken"
          >
            {{ $t('courierSet.authorization.refresh') }}
          </v-btn>
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.shipmentMethod.title')">
      <v-row>
        <v-col>
          <text-field
            v-model="getConfigurationData.configurationName"
            :title="$t('courierSet.shipmentMethod.configurationName')"
            rules="required|not_custom_shipment"
            :disabled="!isAuthorized"
          />
        </v-col>

        <v-col>
          <v-combobox
            v-model="getConfigurationData.courierCode"
            :label="`${$t('courierSet.shipmentMethod.deliverySpeditor')}*`"
            :items="SHIPMENT_METHOD_CODES_SHORT"
            :disabled="!isAuthorized"
            :rules="[required]"
            item-text="name"
            dense
            outlined
            hide-details
            persistent-placeholder
          >
            <template v-slot:selection="{ item }">
              <span v-if="SHIPMENT_METHOD_CODES_SHORT.includes(item)">
                {{ $t(`speditors.${item.toLowerCase()}`) }}
              </span>
              <span v-else> {{ item }}</span>
            </template>
            <template v-slot:item="{ item }">
              {{ $t(`speditors.${item.toLowerCase()}`) }}
            </template>
          </v-combobox>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <text-field
            v-model="getConfigurationData.id"
            :title="$t('courierSet.shipmentMethod.configurationId')"
            rules="required"
            :disabled="true"
          />
        </v-col>
      </v-row>
    </form-section>

    <form-section :title="$t('courierSet.serviceType.title')">
      <v-row>
        <v-col>
          <vee-field
            v-slot="{ errors }"
            :rules="'required'"
          >
            <v-combobox
              v-model="deliveryService"
              persistent-placeholder
              hide-selected
              dense
              outlined
              :label="`${$t('courierSet.serviceType.deliveryMethod')}*`"
              :items="allegroDeliveryServices"
              :item-text="
                (item) =>
                  `${item.name}${item.marketplaces ? ': ' + item.marketplaces.join(',') : ''}${
                    item.carrierId ? `(${item.carrierId})` : ''
                  }`
              "
              return-object
              :disabled="!isAuthorized"
              class="pa-0"
              :error-messages="errors"
              @change="setService()"
            >
              <template v-slot:item="{ item }">
                {{ item.name }}{{ item.marketplaces ? ': ' + item.marketplaces.join(',') : '' }}
                {{ item.carrierId ? `(${item.carrierId})` : '' }}
              </template>
              <template v-slot:selection="{ item }">
                {{ item.name }}{{ item.marketplaces ? ': ' + item.marketplaces.join(',') : '' }}
                {{ item.carrierId ? `(${item.carrierId})` : '' }}
              </template>
            </v-combobox>
          </vee-field>
        </v-col>
      </v-row>
    </form-section>
  </div>
</template>

<script lang="ts">
import { allegroAuthorizationService } from '@/services/spedition/allegroAuthorization';
import { mapGetters } from 'vuex';
import { oidcSettings } from '@/oidc';
import { required } from '@/rules/rules.js';
import { SHIPMENT_METHOD_CODES_SHORT } from '@/constants/ShipmentMethodCodes.js';

import { copyToClipboard } from '@/mixins/helper.js';

export default {
  props: {
    isNew: { type: Boolean, default: false },
    isAuthorized: { type: Boolean, default: false },
  },
  data: () => ({
    SHIPMENT_METHOD_CODES_SHORT,
    allegroDeliveryServices: [],
    deliveryService: {
      id: {
        deliveryMethodId: '',
        credentialsId: '',
      },
      name: '',
      owner: '',
      carrierId: '',
    },
    environment: 'PROD',
    serviceId: '',
    allegroEnvironments: [
      {
        id: 'TEST',
        loginUrl: 'https://allegro.pl.allegrosandbox.pl',
        apiUrl: 'https://api.allegro.pl.allegrosandbox.pl',
        name: 'Sandbox',
      },
      {
        id: 'PROD',
        loginUrl: 'https://allegro.pl',
        apiUrl: 'https://api.allegro.pl',
        name: 'Produkcja',
      },
    ],
  }),
  computed: {
    ...mapGetters('spedition', ['getConfigurationSet', 'getAllegroAuthorization']),
    redirectUri() {
      return `${oidcSettings.post_logout_redirect_uri}allegroAuthorization`;
    },
    getConfigurationData() {
      return this.getConfigurationSet();
    },
  },
  watch: {
    token() {
      this.getDeliveryServices();
    },
  },
  async mounted() {
    if (!this.isNew) {
      if (this.getConfigurationData.refreshFailed) {
        await this.onRefreshAllegroToken();
      }
      this.setEnvironment();
      this.getDeliveryServices();
    } else if (this.isAuthorized) {
      const { accessToken, refreshToken, clientSecret, clientId, environment } =
        this.getAllegroAuthorization();
      this.getConfigurationData.token = accessToken;
      this.getConfigurationData.refreshToken = refreshToken;
      this.getConfigurationData.apiClientSecret = clientSecret;
      this.getConfigurationData.clientId = clientId;
      this.getConfigurationData.apiClientId = clientId;
      this.environment = environment;
      switch (environment) {
        case 'TEST':
          this.getConfigurationData.url = 'https://allegro.pl.allegrosandbox.pl';
          this.getConfigurationData.apiUrl = 'https://api.allegro.pl.allegrosandbox.pl';
          break;
        case 'PROD':
          this.getConfigurationData.url = 'https://allegro.pl';
          this.getConfigurationData.apiUrl = 'https://api.allegro.pl';
          break;
        default:
          break;
      }
      this.getDeliveryServices();
    }
  },
  methods: {
    copyToClipboard,
    required,

    setService() {
      if (!!this.deliveryService && !!this.deliveryService.id) {
        this.getConfigurationData.services.serviceId = this.deliveryService.id.deliveryMethodId;
        this.getConfigurationData.services.credentialsId = this.deliveryService.id.credentialsId;
        this.getConfigurationData.services.serviceCode = this.deliveryService.name;
        this.getConfigurationData.services.name = this.deliveryService.name;
        this.getConfigurationData.services.owner = this.deliveryService.owner;
        this.getConfigurationData.services.carrierId = this.deliveryService.carrierId;
      }
    },
    setEnvironment() {
      if (this.getConfigurationData.apiUrl === 'https://api.allegro.pl') {
        this.environment = 'PROD';
      } else {
        this.environment = 'TEST';
      }
    },
    async getDeliveryServices() {
      const urlParams = new URLSearchParams({
        id: '',
        userId: '',
        apiUrl: this.getConfigurationData.apiUrl,
        token: this.getConfigurationData.token,
      });
      try {
        const { data } = await allegroAuthorizationService.getAllegroDeliveryServices(urlParams);
        this.allegroDeliveryServices = data.services;
        if (this.getConfigurationData.services.serviceId) {
          this.deliveryService = data.services.find(
            (item) => item.id.deliveryMethodId === this.getConfigurationData.services.serviceId,
          );
        }
      } catch (err) {
        console.warn(err);
      }
    },
    onAllegroAuthorization() {
      const selectedEnv = this.allegroEnvironments.find((item) => item.id === this.environment);
      window.location.href =
        `${selectedEnv.loginUrl}/auth/oauth/authorize?` +
        'response_type=code' +
        `&client_id=${this.getConfigurationData.clientId}` +
        `&redirect_uri=${this.redirectUri}` +
        `?nubo_params=${this.getConfigurationData.apiClientSecret},${this.getConfigurationData.clientId},${this.environment}`;
    },

    async onRefreshAllegroToken() {
      try {
        const {
          data: { accessToken, refreshToken },
        } = await allegroAuthorizationService.refreshAllegroToken(this.getConfigurationData.id);
        this.setAllegroAuthorization(accessToken, refreshToken);
        this.$snotify.success(this.$t('courierSet.authorization.refreshSuccess'));
        await this.getDeliveryServices();
      } catch (err) {
        this.$snotify.error(this.$t('courierSet.authorization.refreshError'));
        console.warn(err);
      }
    },

    setAllegroAuthorization(accessToken, refreshToken) {
      this.getConfigurationData.token = accessToken;
      this.getConfigurationData.refreshToken = refreshToken;
    },
  },
};
</script>

<style scoped lang="scss">
.tooltip {
  font-size: 14px;
}

.authorization-info {
  background-color: $success-lighten2 !important;
  padding: 8px 16px;
  margin-bottom: 8px;
  border-radius: 4px;

  span {
    font-size: 14px;
  }
}

.information-div {
  display: flex;
  align-content: space-between;
  justify-items: center;
  background-color: $primary-lighten4;
  border-left: solid 4px $primary-lighten1;
  border-radius: 4px;
  padding-top: 8px;
  font-size: 14px;
  font-weight: 400;
  text-align: start;
}

.link a {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.redirection-info-div {
  text-align: start;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
</style>
